<template>
        <div>           
            
            <!-- produse -->
            <el-tabs class="produse" type="border-card" v-model="tab"  @tab-click="handleTab">
              <span style="float:left; background-color:#EBEEF5; padding:10px; margin-bottom:10px;">Grupa {{nrOrdine}}</span> 
                <el-button-group style="float:right; margin-bottom: 10px;">
                    <el-button type="warning" icon="el-icon-bottom" @click="down(item, index)"></el-button>
                    <el-button  type="warning" icon="el-icon-top" @click="up(item, index)"></el-button>
                    <el-button  type="danger" icon="el-icon-close" @click="deleteElement(index)"></el-button>
                </el-button-group>

                <el-tab-pane label="Piese" >
                    <produse style="margin-bottom:10px" ref="tbl-piese" tip='Piese' />
                </el-tab-pane>

                <el-tab-pane label="Manopera" >
                    <produse style="margin-bottom:10px" ref="tbl-manopera" tip='Manopera'  />
                </el-tab-pane>

                <el-tab-pane label="Kit" >
                    <produse style="margin-bottom:10px" ref="tbl-kit" tip='Kit' /> 
                </el-tab-pane>

            </el-tabs>                        

        </div>
</template>

<script>
    import settings from "@/backend/LocalSettings";
    import BasePage from '@/pages/BasePage';
    import ProduseWidget from '@/components/grupe_produse/ProduseWidget.vue';
    

    export default {
        name: "Grupa-produse",
        extends: BasePage,
        components: {
            'produse': ProduseWidget
        },
        props:{
            item: {
                type: Object,
                required: true
            }, 
            index: {
                type: Number,
                required: true
            },
            nrOrdine: {
                type: Number,
                required: true
            }
        },    
        watch: {
            item(val) {
                if (!this.item.ProdusePiese) {
                    this.item.ProdusePiese = []
                }
                if (!this.item.ProduseManopera) {
                    this.item.ProduseManopera = []
                }
                if (!this.item.ProduseKit) {
                    this.item.ProduseKit = []
                }
            }
        },
        data () {
            return {
                tab: '',
                produse: [],
                obiect: {
                    LocalId:'',
                    NumarOrdine: '',
                    ProdusePiese:'',
                    ProduseManopera:'',
                    ProduseKit:''
                }
                
            }
        },
        methods: {                                    
            up(item, index){
                this.$emit('up', {item, index})
            },  
            down(item, index){   
                this.$emit('down', {item, index})
            },   
            
            deleteElement(index) {
                this.$emit('delete', index)
            }, 
            setNumarOrdine(nrOrdine) {
                this.obiect.NumarOrdine = nrOrdine
            },
            handleTab(tab) {                              
                //this.tab = tab.label.toLowerCase(); 
                this.$refs['tbl-piese'   ].set_tip( 'piesa' );
                this.$refs['tbl-manopera'].set_tip( 'manopera' );
                this.$refs['tbl-kit'     ].set_tip( 'kit' );
            },

            getGrupa() {                               

                this.obiect.LocalId         = this.item.LocalId
                this.obiect.NumarOrdine     = this.nrOrdine
                this.obiect.ProdusePiese    = this.$refs['tbl-piese'   ].get_produse()
                this.obiect.ProduseManopera = this.$refs['tbl-manopera'].get_produse()
                this.obiect.ProduseKit      = this.$refs['tbl-kit'     ].get_produse()

                return this.obiect               
            },

            
        },
        mounted() {
            if (!this.item.ProdusePiese) {
                this.item.ProdusePiese = []
            }
            if (!this.item.ProduseManopera) {
                this.item.ProduseManopera = []
            }
            if (!this.item.ProduseKit) {
                this.item.ProduseKit = []
            }
            this.$refs['tbl-piese'   ].set_produse( this.item.ProdusePiese );
            this.$refs['tbl-manopera'].set_produse( this.item.ProduseManopera );
            this.$refs['tbl-kit'     ].set_produse( this.item.ProduseKit );
        }
    };
</script>

<style lang="less" scoped>
    .full-width {
        width: 100%;
    }

</style>