<template>
    <div>
        <table class='table table-sm'>
            <thead>
                <tr>
                    <th>Produs</th>
                    <th>Detalii</th>
                    <th>Cantitate</th>
                    <th>Pret</th>
                    <th>Discount %</th>
                    <th>Pret Final</th>
                    <th>Valoare</th>
                    <th>Actiuni</th>
                </tr>
            </thead>
            <tbody> 
                <tr>
                    <td style="width:250px">

                        <el-autocomplete class="full-width" size='mini' v-model="info.nume_produs" placeholder="Cautare..." :class="{ 'eroare_input': err_chenar && !info.nume_produs }"
                            :fetch-suggestions="cautaProdus" @select="handleSelectProdus">                            
                        </el-autocomplete>
                        <span class="error-text" v-show="!info.nume_produs && submitProdus">Campul este obligatoriu</span>

                    </td> 
                    <td>
                        <el-input size="mini" v-model="info.detalii_produs"></el-input>
                    </td>
                    <td>
                        <el-input-number size='mini' v-model="info.cantitate" :min="1" :max="99999" :precision='2'></el-input-number>
                    </td>
                    <td>
                        <el-input-number size='mini' v-model="info.pret" :min="1" :max="99999" :precision='2'></el-input-number>
                    </td>
                    <td>
                        <el-input-number size='mini' v-model="info.discount" :min="0" :max="100" :precision='2'></el-input-number>
                    </td>
                    <td>
                        {{aplica_discount(info.pret, info.discount) |format_money}} Lei
                    </td>
                    <td>
                        {{(aplica_discount(info.pret, info.discount)* info.cantitate)|format_money}} Lei
                    </td>
                    <td>
                        <el-button type='primary' size='mini' @click="adauga_produs()">Adauga</el-button>
                    </td>
                </tr>
            </tbody>
        </table>
        <hr/>
        <el-table :data='produse'>
            <el-table-column prop='nume_produs' label="Produs"></el-table-column>
            <el-table-column prop='detalii_produs' label="Detalii">
                <template slot-scope="scope">
                    <el-input size="mini" v-model="scope.row.detalii_produs"></el-input>
                </template>
            </el-table-column>
            <el-table-column  label="Cantitate">
                <template slot-scope="scope" >
                    <el-input-number size='mini' v-model="scope.row.cantitate" :min="1" :max="99999" :precision='2'></el-input-number>
                </template>
            </el-table-column>
            <el-table-column label="Pret">
                <template slot-scope="scope" >
                    <el-input-number size='mini' v-model="scope.row.pret" :min="1" :max="99999" :precision='2'></el-input-number>
                </template>
            </el-table-column>
            <el-table-column label="Discount">
                <template slot-scope="scope" >
                    <el-input-number size='mini' v-model="scope.row.discount" :min="0" :max="100" :precision='2'></el-input-number>
                </template>
            </el-table-column>
            <el-table-column label="Pret Final">
                <template slot-scope="scope" >
                    {{aplica_discount(scope.row.pret, scope.row.discount) | format_money}} Lei
                </template>
            </el-table-column>
            <el-table-column label="Valoare">
                <template slot-scope="scope" >
                    {{(aplica_discount(scope.row.pret, scope.row.discount)* scope.row.cantitate)|format_money}} Lei
                </template>
            </el-table-column>
            <el-table-column label="Actiuni">
                <template slot-scope="scope" >
                    <el-tooltip content="Sterge" >
                        <el-button size='mini' type="danger" icon="el-icon-delete" circle @click="delete_item(scope.row)" />
                    </el-tooltip>
                </template>
            </el-table-column>
        </el-table>
    </div>
</template>

<script>
    
    import settings from "@/backend/LocalSettings";
    import BasePage from '@/pages/BasePage';

    export default {
        name: "produse_widget",
        extends: BasePage,
        props:{
            tip: {
                type: String,
                required: true,
                default: "Piese"
            }
        },
        data () {
            return {
                info:
                {
                    tip_produs: 'piesa',
                    nume_produs: '',
                    detalii_produs: '',
                    id_produs: '',
                    cantitate: 1,
                    pret: 0,
                    discount: 0,
                    loading: false,
                    options: []
                                    
                },
                produse: [],
                err_chenar: false,
                submitProdus: false
            }
        },
        methods: {

            reset_info(){
                this.produse = []                
            },
            
            aplica_discount( valoare, discount ){
                if( discount > 0 )
                {
                    var procent_decimal = ( (100+discount)/100 ) - 1;
                    return valoare - ( valoare * procent_decimal );
                }
                return valoare;
            },
            adauga_produs(){
                this.submitProdus  = true
                 if(!this.info.id_produs){
                     this.err_chenar = true
                     return false
                 } else {
                     this.submitProdus  = false
                     this.err_chenar    = false                                
                 }
                var info          = this.info;
                let id_cautat     = this.info.id_produs;
                var items         = this.info.options.filter( x => x.Id == id_cautat);
                var produs_cautat = {};
                if( items.length>0 ) produs_cautat = items[0];
                let pret_final = this.aplica_discount(this.info.pret, this.info.discount);
                let valoare    = pret_final * this.info.cantitate;

                let new_product = {
                    tip_produs: this.info.tip_produs,
                    detalii_produs: this.info.detalii_produs,
                    id_produs:  this.info.id_produs,
                    nume_produs: this.info.nume_produs,
                    cantitate:  this.info.cantitate,
                    pret:       this.info.pret,
                    discount:   this.info.discount,
                    pret_final: pret_final,
                    valoare:    valoare,
                    tip:        this.tip,
                };
                
                this.produse.push(new_product);
                this.info = {                    
                        tip_produs: this.info.tip_produs,
                        nume_produs: '',
                        detalii_produs: '',
                        id_produs: '',
                        cantitate: 1,
                        pret: 0,
                        discount: 0,
                        loading: false,
                        options: []
                };
            },
            set_produse(produse){
                
                this.produse = produse;                
            },
            get_produse(){
                var me = this
                for(var i=0; i<this.produse.length; i++) {
                    me.produse[i].pret_final = me.aplica_discount(me.produse[i].pret, me.produse[i].discount);
                    me.produse[i].valoare    = me.produse[i].pret_final * me.produse[i].cantitate;
                } 

                return this.produse;
            },
            set_tip(tip){
                this.info.tip_produs = tip
            },            
            async cautaProdus(queryString, cb){
                if(queryString != '')
                {
                    var response = await this.post("oferte/search_produs", {queryString: queryString, tip_produs: this.info.tip_produs})
                    cb(response.Results)
                }
            },
            handleSelectProdus(item){    
                this.info.id_produs = item.id   
                this.info.pret      = item.ProdusComplet.Pret
            },
            async delete_item(item){
                var confirm =  await this.$confirm(`Sunteti sigur ?`, 'Warning')
                if( confirm ){
                    var index = this.produse.indexOf(item)
                    this.produse.splice(index,1)                                       
                }                                               
            }           
            
        },
        mounted: function() {
        }
    };
</script>

<style lang="less" scoped>
    .full-width {
        width: 100%;
    }
    .check-360-table{
        font-size: 12px;
        td{
            padding: 0px !important;
        }
    }

    .error-text{
        font-size: 12px;
        color: red;
        line-height:18px !important;
    }

    .eroare_input{
        .el-input__inner{
        border-color: red;
        }
  }

</style>