<template>
    <el-card>
        <div slot="header" class="clearfix">
            <el-button type="warning" @click="adaugaGrupa">Adauga grupa noua</el-button> 
        </div>
        <el-row :gutter="15">
            <el-col :md="24" v-for="(item,index) in grupe_produse" :key="item.LocalId" style="margin-bottom: 15px;">  
               
                <grupa-produse  :ref="'grupa' + item.LocalId" :item.sync="item" :index.sync="index" :nrOrdine="parseInt(item.NrOrdine)" @up="up" @down="down" @delete="deleteElement" @afterProductModification="updateGrupa(item)" />
                                                                     
            </el-col>
        </el-row>   
    </el-card>             
</template>

<script>
    import settings from "@/backend/LocalSettings";
    import BasePage from '@/pages/BasePage';    
    import GrupaProduse from '@/components/grupe_produse/GrupaProduse.vue';

    export default {
        name: "Lista-grupe",
        extends: BasePage,    
        props:['MasinaId'],    
        components:{            
            'grupa-produse': GrupaProduse,
        },
        data () {
            return {
                grupe_produse: [ {NrOrdine:1, LocalId: this.newLocalId()} ],
                produse: {}
            }
        },
        methods: {    
            
            show(){
                
                this.grupe_produse = [ {NrOrdine:1, LocalId: this.newLocalId()} ],
                this.produse = {}
                this.$forceUpdate()
            },
            
            emptyProduse(){
                
                this.grupe_produse = [ {NrOrdine:1, LocalId: this.newLocalId()} ],
                this.produse = {}
                this.$forceUpdate()
            },

            newLocalId() {
                let d = new Date();
                return d.getTime()
            },
            adaugaGrupa(){
                // let nr = this.grupe_produse[this.grupe_produse.length -1].NrOrdine
                // nr++     
                let nr = 0       
                //this.grupe_produse.push({NrOrdine: nr, LocalId: this.newLocalId()})
                this.grupe_produse.unshift({NrOrdine: nr, LocalId: this.newLocalId()})
                //
                this.resetNrOrdine();
            },
            up(data){                
                let {item, index} = data
               
                if (index <= 0) {
                    return
                }               
                
                let temp = this.grupe_produse[index];
                this.grupe_produse[index] = this.grupe_produse[index - 1]
                this.grupe_produse[index - 1] = temp

                this.updateNumarOrdineInComponenta(index)

                this.resetNrOrdine()
            },  
             down(data){       
                let {item, index} = data                 

                if (index >= this.grupe_produse.length - 1) {
                    return
                }

                let temp = this.grupe_produse[index];                
                this.grupe_produse[index] = this.grupe_produse[index + 1]
                this.grupe_produse[index + 1] = temp
                
                this.updateNumarOrdineInComponenta(index)
                this.resetNrOrdine()
            },   
            resetNrOrdine() {
                this.grupe_produse.forEach( (item, index) => {
                    item.NrOrdine = index + 1
                })
                this.grupe_produse.push({})
                this.grupe_produse.pop()
                this.$forceUpdate()
            },
            updateNumarOrdineInComponenta(indexComponenta) {
                var numeRef = 'grupa'+this.grupe_produse[indexComponenta].LocalId 
                this.$refs[numeRef][0].setNumarOrdine(indexComponenta)
            },
            deleteElement(index) {
                if (this.grupe_produse.length <= 1) {
                    return
                }     
                if (index < 0) {
                    return
                }     
                if (index > this.grupe_produse.length - 1) {
                    return
                }
                
                this.grupe_produse.splice(index, 1)
                
                this.resetNrOrdine()
            },
            getGrupeProduse(param){

                var grupeProduse = [];
                
                for(var i=0; i<this.grupe_produse.length;i++){
                    var numeRef = 'grupa'+this.grupe_produse[i].LocalId
                    
                    grupeProduse.push(this.$refs[numeRef][0].getGrupa())
                }           
                
                return grupeProduse
            },

            setGrupeProduse(grupe){
                this.grupe_produse = grupe

            }
           
            
        }, 
        mounted: function() {            
            this.show()
        },
       
    };
</script>

<style lang="less" scoped>
    .full-width {
        width: 100%;
    }

</style>