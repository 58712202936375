<template>
    <el-dialog title="Oferte" :visible.sync="showPopup" :fullscreen="true" append-to-body>
        <el-form label-position="top" :inline="false" :rules="rules" label-width="100%" :model='selectedObject' ref='my-form' @submit.prevent="save" v-loading="loadingVisible" >
            <el-row :gutter="15" >
                <el-col :md="4">
                    <p style="margin-bottom:0px;background-color: antiquewhite;" v-if="cerereOfertaClient.AreCerereOferta == '1'"> {{cerereOfertaClient.DescriereCerereOferta}} </p> <br>
                    <strong>Informatii check 360</strong><br>
                    <strong>Mecanic: {{mecanic}}</strong><br>
                    <strong>Partener: {{partener}}</strong>

                    <el-table style="margin-bottom:30px;" class='check-360-table' :data="Check">
                        <el-table-column prop="Intrebare" label="Intrebare" show-overflow-tooltip>
                            <template slot-scope="scope">
                                {{ scope.row.Intrebare }} 
                                 <br><div style="margin-left:5px; color:gray;" v-if="scope.row.Observatii != '' || scope.row.Observatii != null"> *{{scope.row.Observatii}} </div>
                            </template>
                        </el-table-column>
                        <el-table-column prop="Raspuns" :width='100' label="Rezultat"></el-table-column>
                    </el-table>

                    <strong style="margin-top: 30px; margin-left:7.5px;">Observatii:</strong><br>
                    <div v-if="CheckComplet!= null " style="margin-top: 10px; margin-left:7.5px;"> {{CheckComplet.Observatii}} </div>

                </el-col>
                <el-col :md='20'>
                    <el-col :md='8'>
                        <el-form-item label='Masina' prop="IdMasina">
                            <el-input v-model='Info.masina.Numar'  class='full-width'/>                                                                   
                        </el-form-item>
                    </el-col>
                    <el-col :md='8'>
                        <el-form-item label='Client' prop="IdClient">
                            <el-select v-model='selectedObject.IdClient'  class='full-width'>
                                <el-option v-for='item in Info.clienti' :key="'clienti' + item.Id" :label='item.Denumire' :value='item.Id'></el-option>
                            </el-select>
                        </el-form-item>
                    </el-col>
                    <el-col :md='8'>
                        <el-form-item label='Observatii' >
                            <el-input  class='full-width' v-model='selectedObject.Observatii' />
                        </el-form-item>
                    </el-col> 
                    <el-col :md="24">

                        <lista-grupe ref="lista"/>
                     
                    </el-col>                      
                </el-col>                
            </el-row>
        </el-form>
        <span slot="footer" class="dialog-footer" >
            <el-button @click="resetForm('my-form')"           > Renunta  </el-button>
			<el-button type="primary" @click="save('my-form')" > Salveaza </el-button>
        </span>

    </el-dialog>
</template>

<script>
    import settings from "@/backend/LocalSettings";
    import BasePage from '@/pages/BasePage';
    import ProduseOfertaWidget from '@/pages/oferte/ProduseOfertaWidget.vue';
    import ListaGrupe from '@/components/grupe_produse/ListaGrupe.vue';

    export default {
        name: "Oferte_dialog",
        extends: BasePage,    
        props:['MasinaId'],    
        components:{
            'produse-oferta': ProduseOfertaWidget,
            'lista-grupe': ListaGrupe
        },
        watch: {
            CheckComplet(val){
                console.log(val)
            }
        },
        data () {
            return {
                showPopup: false,
                mode: 'add',
                selectedObject: { IdMasina: '-1' , IdClient: '' ,  Observatii: '' },      
                Info:{ masina: {}, clienti: [] },                    
                rules: {
                    IdMasina: [ { required: true, message: 'Campul este obligatoriu' } ], 
                    IdClient: [ { required: true, message: 'Campul este obligatoriu' } ],
                },
                tab: '',
                Check: [],
                mecanic: '',
                partener: '',
                CheckComplet:'',
                grupeProduse: '',
                grupeErori: [],
                cerereOfertaClient:{}
            }
        },
        methods: {
            show_me: async function( id, idMasina=-1) {      
                  
                this.selectedObject             = { IdMasina: idMasina , IdClient: '' , Observatii: '' }
                this.selectedObject.IdClient    = this.Info.clienti[0].Id   
                this.showPopup                  = true;
                let res                         = await this.post('Checklist/getUltimulChecklist', { IdMasina: idMasina })
                this.Check                      = res.Check
                this.mecanic                    = res.Mecanic
                this.partener                   = res.Partener
                this.CheckComplet               = res.Checklist_complet                       
                this.cerereOfertaClient         = res.CerereOfertaClient
                if( id == null )
                {
                    this.mode = "add";     
                    this.$refs.lista.emptyProduse();            
                }
                else
                {
                    this.mode           = "edit";
                    var result          = await this.post("oferte/get_info_item_dialog", { id: id } );
                    this.selectedObject = result.Item;
                    this.grupeProduse   = result.grupe;

                    this.$refs.lista.setGrupeProduse(this.grupeProduse)                 
                }
            },
            async get_info(){                
                var response        = await this.post("oferte/get_info_for_dialog" ,{id_masina: this.MasinaId});
                this.Info.masina    = response.masina;            
                this.Info.clienti   = response.clienti;  
            },

            verificaGrupe(){
                var ret         = false
                this.grupeErori = []

                this.grupeProduse.forEach((g) => {
                    if(g.ProdusePiese.length != 0 || g.ProduseManopera.length != 0 || g.ProduseKit.length != 0) 
                        ret = true
                    else 
                        this.grupeErori.push(g.NumarOrdine)
                })

                if (this.grupeErori.length > 0) 
                    ret = false
                else 
                    ret = true

                return ret
            },
          
            save: async function() 
            {                           
                this.grupeProduse = this.$refs.lista.getGrupeProduse(); 

                this.verificaGrupe();                
                                
                this.$refs['my-form'].validate( async(valid) => {
                    if (valid)
                    {
                        
                        if (!this.verificaGrupe()) 
                        {             
                            var mesaj = ''      
                            
                            if(this.grupeErori.length == 1)
                                mesaj = '<strong>Grupa ' + this.grupeErori.map(e => e) + '</strong>' + ' nu are produse adaugate'      
                                
                            if(this.grupeErori.length > 1)
                                mesaj = '<strong>Grupele ' + this.grupeErori.map(e => ' ' + e) + '</strong>' + ' nu au produse adaugate' 
                        
                            this.$notify.error({
                                dangerouslyUseHTMLString: true,
                                title: 'Atentie!',
                                message: mesaj,
                                offset: 100
                            });

                            return
                        }

                        await this.post("oferte/save", { mode: this.mode, object: this.selectedObject, grupe: this.grupeProduse } );
                            this.resetForm()
                            this.$emit("save");
                    } else console.log('eroare validare formular!')
                });
            },
            resetForm() {
				this.$refs['my-form'].resetFields()
				this.showPopup = false
            },
            //
            handleTab(tab) {                              
                let tipProdus = tab.label.toLowerCase();                
                this.$refs['tbl-piese'   ].set_tip( 'piesa' );
                this.$refs['tbl-manopera'].set_tip( 'manopera' );
                this.$refs['tbl-kit'     ].set_tip( 'kit' );
            },
            newLocalId() {
                let d = new Date();
                return d.getMilliseconds()
            },
            adaugaGrupa(){
                let nr = this.grupe_produse[this.grupe_produse.length -1].NrOrdine
                nr++
                this.grupe_produse.push({NrOrdine: nr, LocalId: this.newLocalId()})
            },
            up(item, index){               

                if (index <= 0) {
                    return
                }

                let temp = this.grupe_produse[index];
                this.grupe_produse[index] = this.grupe_produse[index - 1]
                this.grupe_produse[index - 1] = temp

                this.resetNrOrdine()
            },  
             down(item, index){
                
              
                if (index >= this.grupe_produse.length - 1) {
                    return
                }

                let temp = this.grupe_produse[index];
                this.grupe_produse[index] = this.grupe_produse[index + 1]
                this.grupe_produse[index + 1] = temp
                this.resetNrOrdine()
            },       
            //
            resetNrOrdine() {
                this.grupe_produse.forEach( (item, index) => {
                    item.NrOrdine = index + 1
                })
                this.grupe_produse.push({})
                this.grupe_produse.pop()
                this.$forceUpdate()
            },
            deleteElement(index) {
                this.grupe_produse.splice(index, 1)
            }
        }, 
        mounted: function() {            
            this.get_info();
        }
    };
</script>

<style lang="less" scoped>
    .full-width {
        width: 100%;
    }
    .produse {
        z-index: 99999999 !important; 
    }

</style>